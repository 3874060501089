import GenericDAO from "@/middleware/generic-dao";

const getAll = async () => {
    const esp = sessionStorage.getItem('especialidades');
    if (esp) return JSON.parse(esp);

    const esps = await GenericDAO.find(`/webcli/especialidade/`, { ignorarEstabelecimento: true });
    if([200,201,204].includes(esps.status)) sessionStorage.setItem('especialidades', JSON.stringify(esps.data));
    return esps.data;
}

export default { getAll }
