<template lang="pug">
span
    Filtros(:filtros='filtersOptions' @filters="mudaFiltros")
    ProgressBar(v-if='waiting' mode="indeterminate")
    p.ta-center.text-secondary(v-else-if='list.length == 0') Nenhum registro encontrado.
    div(:style="{'display':waiting ? 'none' : 'block'}" v-else)
        DataView.dataview.my-2(:value="list" layout="grid")
            template(#grid="{data}")
                .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !data.ie_status }")
                    Panel.panel-list.ta-center(:header="data.nm_pessoa_fisica" style='position: relative')
                        .ta-left
                            p(v-tooltip.top='data.nm_razao_social')
                                b Nome: 
                                span {{ data.nm_fantasia }}
                            p
                                span(style="float:left; width:80%; border-top:1px solid black; border-bottom:1px solid black;")
                                    b Endereço: 
                                    span {{ data.nm_rua + ', Nº '+ data.nr_estabelecimento + ', '+ data.nm_bairro + ', ' + data.ds_cidade + ', CEP ' + data.nr_cep +';'}}
                                    br
                                    span(v-if='data.ds_complemento') 
                                        b Complemento: 
                                        span {{ data.ds_complemento }};
                            span(style="float:right; width:20%;")
                                Button.p-button-copy(icon='jam jam-files' @click="copy(data)" v-tooltip.top='"Copiar Endereço e Contato"')
                                Button.p-button-warning(v-tooltip.top='"Corpo Clínico"' icon='jam jam-medical' @click="ccDialog(data.id, data.nm_fantasia)")
                            p
                                b Contatos:
                                span(v-if='data.nr_whatsapp_cliente')
                                    br
                                    span {{ data.nr_whatsapp_cliente_f }}
                                    a.whatsapp-link(:href="`https://api.whatsapp.com/send?phone=${ data.nr_telefone_cliente_ws }`" target='_blank')
                                        i(class='jam jam-whatsapp')
                                span(v-if='data.nr_telefone_f')
                                    br
                                    span {{ data.nr_telefone_f }}
                                span(v-if='data.nr_whatsapp')
                                    br
                                    span {{ data.nr_whatsapp_f }}
                                    a.whatsapp-link(:href="`https://api.whatsapp.com/send?phone=${ data.nr_telefone_ws }`" target='_blank')
                                        i(class='jam jam-whatsapp')

        Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

        Panel.datatable(header='Lista de Locais de Atendimento')
            Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
            DataTable(:value="list" @sort="onSort($event)" :sortField="order.field" :sortOrder="order.sortOrder" )
                ColumnGroup(type='header')
                    Row
                        Column(header='Foto' :rowspan='2' headerStyle='width: 15%;')
                        Column(header='Nome' :rowspan='2' headerStyle='width: 15%;' sortable)
                        Column(header='Logradouro' :rowspan='2' headerStyle='width: 28%;')
                        Column(header='Contato' :rowspan='1' :colspan='2')
                        Column(header='Ações' :rowspan='2' headerStyle='width: 10%; !important')
                    Row
                        Column(header='Estabelecimento' :rowspan='1' headerStyle='width: 16%;')
                        Column(header='Coordenação' :rowspan='1' headerStyle='width: 16%;')
                Column(field='aq_foto')
                    template(#body='{data}')
                        .avatar-wrapper
                            img(:src='data.aq_foto' :alt="data.nm_fantasia")
                Column(field='nm_fantasia' sortable)
                    template(#body='{data}')
                        span(v-tooltip.end='data.nm_razao_social') {{ data.nm_fantasia }}
                Column(bodyStyle='vertical-align: middle;' field='logradouro')
                    template(#body='{data}')
                        span(v-html="listaEnderecoEstabelecimento(data)")
                Column(bodyStyle='text-align: left;')
                    template(#body='{data}')
                        span(v-if='data.nr_whatsapp_cliente' v-tooltip.top='"Whatsapp do Estabelecimento"')
                            span {{ data.nr_whatsapp_cliente_f }}
                            a.whatsapp-link(:href="`https://api.whatsapp.com/send?phone=${ data.nr_telefone_cliente_ws }`" target='_blank')
                                i(class='jam jam-whatsapp')
                            br
                        span(v-if='data.nr_telefone_f' v-tooltip.top='"Telefone do Estabelecimento"')
                            span {{ data.nr_telefone_f }}
                Column(bodyStyle='text-align: left;')
                    template(#body='{data}')
                        span(v-if='data.nr_whatsapp' v-tooltip.top='"Whatsapp da Coordenação"')
                            span {{ data.nr_whatsapp_f }}
                            a.whatsapp-link(:href="`https://api.whatsapp.com/send?phone=${ data.nr_telefone_ws }`" target='_blank')
                                i(class='jam jam-whatsapp')
                Column(header='Ações' bodyStyle='text-align: center;')
                    template(#body='{data}')
                        Button.p-button-copy(icon='jam jam-files' @click="copy(data)" v-tooltip.top='"Copiar Endereço"')
                        Button.p-button-warning(v-tooltip.top='"Corpo Clínico"' icon='jam jam-medical' @click="ccDialog(data.id, data.nm_fantasia)")
            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")  
</template>

<script>
import ProgressBar from 'primevue/progressbar'
import DataView from 'primevue/dataview'
import Panel from 'primevue/panel'
import Paginator from 'primevue/paginator'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import MultiSelect from 'primevue/multiselect'
import InputSwitch from 'primevue/inputswitch'
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import Button from "primevue/button";
import InputText from 'primevue/inputtext'
import moment from 'moment'
import { Estabelecimento } from './../../middleware'
import wsConfigs from './../../middleware/configs'
import { fixTelefoneFormat, listaEnderecoEstabelecimento, copyToClipboard } from './../../utils'
import Tooltip from 'primevue/tooltip'
import Filtros from './Filtros.vue'
import * as _ from 'lodash'

export default {
    components: { ProgressBar, DataView, Panel, Paginator, DataTable,
            Column, Button, InputText, ColumnGroup, Row, MultiSelect, InputSwitch, Filtros },
    directives: { tooltip: Tooltip },
    emits: ['cc-dialog'],
    computed: {
        show: {
            get() { return this.visible; },
            set(val) { if (!val) this.$emit('close'); }
        }
    },
    data () {
        return {
            list: [],
            windowInnerWidth: window.innerWidth,
            waiting: false,
            waitingEspecialidades: false,
            filters: {
                ds_busca: '',
                cd_especialidades_list: []
            },
            filtersOptions: {
                estabelecimentos: true,
                especialidades: true,
                localidade: true,
            },
            paginator: {
                page: this.$route.query.pg || 1,
                per_page: wsConfigs.paginator_perPage,
                count: 0
            },
            order: {
                field: 'nm_fantasia',
                sortOrder: 1
            },
            options: {
                especialidades: []
            }, 
            getListDebounce: _.debounce(function (params, getFunction) {
                getFunction(params)
            }, 500, { leading: true }),
        }
    },
    methods: {
        getList (params) {
            if(this.$parent.medEmpresa) console.log("Olha")
            this.waiting = true
            if(this.$route.name == "locais_med_empresa") params.ie_atende_medclub_empresa = true;
            return Estabelecimento.byEspecialidades(params).then(response => {
                if (response?.status == 200) {
                    this.paginator.count = response.data.count
                    response.data.results.forEach(estabelecimento => {
                        if (estabelecimento.nr_telefone) estabelecimento.nr_telefone_f = fixTelefoneFormat(estabelecimento.nr_telefone)
                        
                        if(estabelecimento.nr_whatsapp){ 
                            estabelecimento.nr_whatsapp_f = fixTelefoneFormat(estabelecimento.nr_whatsapp)
                            estabelecimento.nr_telefone_ws = estabelecimento.nr_whatsapp.match(/\d+/g).join('')
                            estabelecimento.nr_telefone_ws.substr(0, 2) === '55' ? estabelecimento.nr_telefone_ws = `${ estabelecimento.nr_telefone_ws }` :  estabelecimento.nr_telefone_ws = `55${ estabelecimento.nr_telefone_ws }`
                        }

                        if(estabelecimento.nr_whatsapp_cliente){ 
                            estabelecimento.nr_whatsapp_cliente_f = fixTelefoneFormat(estabelecimento.nr_whatsapp_cliente)
                            estabelecimento.nr_telefone_cliente_ws = estabelecimento.nr_whatsapp_cliente.match(/\d+/g).join('')
                            estabelecimento.nr_telefone_cliente_ws.substr(0, 2) === '55' ? estabelecimento.nr_telefone_cliente_ws = `${ estabelecimento.nr_telefone_cliente_ws }` : estabelecimento.nr_telefone_cliente_ws = `55${ estabelecimento.nr_telefone_cliente_ws }`
                        }
                    })
                    this.list = response.data.results
                }else{
                    this.list = []
                }
                this.waiting = false
                return true
            })
        },
        checkEmptyField (field) {
            if (! this.filters[field].length) this.applyFilters()
        },
        applyFilters (page) {
            this.paginator.page = page || 1
            let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }

            params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`

            if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
            Object.keys(this.filters).forEach((key) => {
                if (key == 'nr_name') params[key] = this.filters[key].replace(/(\.|-|_)/g, '')
                else if (key.substr(0, 3) == 'dt_') {
                    if (moment(this.filters[key], 'DD/MM/YYYY').isValid())
                        params[key] = moment(this.filters[key], 'DD/MM/YYYY').format('YYYY-MM-DD')
                } else if (this.filters[key]) params[key] = this.filters[key]
            })
            // Fake loading quando os filtros forem os mesmos
            if (_.isEqual(this.params, params)) {
                if (!this.waiting) {
                    this.waiting = true
					setTimeout(() => this.waiting = false, 200)
				}
			} else {
                this.params = Object.assign({}, params)
				this.waiting = true
				this.getListDebounce(params, this.getList)
			}
        },
        onPage (ev) {
            this.applyFilters(ev.page + 1)
        },
        onSort(ev) {
            this.order.field = ev.sortField
            this.order.sortOrder = ev.sortOrder
            this.applyFilters()
        },
        copy(propsData){
            copyToClipboard(listaEnderecoEstabelecimento(propsData, true))
            this.$toast.success('Endereço e Contato copiados!')
        },
        ccDialog (estabId, estabName) {
            this.$emit('cc-dialog', {dialogCClinico: true, estabId, estabName})
        },
        listaEnderecoEstabelecimento,
        mudaFiltros(fil) {
            this.filters = fil
            this.applyFilters()
        }
    }
}
</script>