<template lang="pug">
Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' )
    .p-grid.p-fluid.p-align-end
        .p-col-12.p-md-4(v-if='filtros.estabelecimentos')
            label.form-label Estabelecimento:
            .p-inputgroup
                InputText(
                    placeholder='Nome Fantasia, Razão Social ou CNPJ'
                    :autoClear='false'
                    @keyup.enter.native='emitFilters'
                    v-model='filters.ds_busca'
                )
                Button(icon='jam jam-search' @click='emitFilters')
        .p-col-12.p-md-4(v-if="filtros.especialistas")
            label.form-label Especialista:
            .p-inputgroup
                InputText(
                    placeholder='Nome do Especialista'
                    :autoClear='false'
                    @keyup.enter.native='emitFilters()'
                    v-model='filters.nm_especialista'
                )
                Button(icon='jam jam-search' @click='emitFilters()' focusable=false)
        .p-col-12.p-md-4(v-if='filtros.especialidades')
            MultiSelectEspecialidades(
                @lista-especialidades='mudaEspecialidades'
                :height="multiHeight"
            )
        .p-inputgroup.p-col-12.p-md-4.p-lg-4.pa-0(v-if="filtros.localidade")
            .p-col-4.p-lg-4.p-md-5
                label.form-label Estado:
                Dropdown(v-model='filters.cd_estado' :options='options.estados' optionLabel="ds_sigla" optionValue='id' style='text-align: center')
            .p-col-8.p-lg-8.p-md-7
                label.form-label Cidade:
                Dropdown(v-model='filters.cd_cidade' :options='options.cidades' optionLabel="nm_cidade" optionValue='id' filter=true style="text-align:center" filterPlaceholder='Busque uma cidade...')
        .p-col-12.p-md-2(v-if='filtros.agenda' style="float:left")
            label.form-label Agenda:
            SelectButton.selectButton(v-model='filters.ie_sem_agenda' :options='options.agenda' optionLabel='label' optionValue='value' @input="emitFilters()")
                template(#option='agendaOptions')
                    div(v-tooltip.bottom='agendaOptions.option.label+" Agenda"')
                        i(:class="agendaOptions.option.icon")
</template>
<script>
    import Panel from 'primevue/panel';
    import InputText from 'primevue/inputtext';
    import Button from 'primevue/button';
    import MultiSelectEspecialidades from '../CustomComponents/MultiSelectEspecialidades.vue';
    import SelectButton from 'primevue/selectbutton';
    import Dropdown from 'primevue/dropdown';
    import Tooltip from 'primevue/tooltip';
    import _ from 'lodash'

    export default {
        created()  { this.listaCidades() },
        components: { Panel, MultiSelectEspecialidades, InputText, Button, SelectButton, Dropdown },
        directives: { tooltip: Tooltip },
        emits: ["filters"],
        props: {
            filtros: {
                type: Object,
                required: true,
            },
            multiHeight: {
                type: String,
                required: false,
            }
        },
        data () {
            return {
                windowInnerWidth: window.innerWidth,

                filters: {
                    nm_especialista: '',
                    ds_busca: '',
                    ie_sem_agenda: 'false',
                    cd_estado: 9,
                    cd_cidade: 0,
                    cd_especialidades_list: ''
                },
                options: {
                    agenda: [
                        {label: 'Com', value: 'false', icon: 'jam jam-calendar-alt'}, 
                        {label: 'Sem', value: 'true', icon: 'jam jam-calendar'},
                        {label: 'Com e Sem', value: null, icon: 'jam jam-calendar-alt-f'}
                    ],
                    estados: [{id:0, ds_sigla: 'Todos' },...JSON.parse(localStorage.getItem('usuario')).opcoes_estado],
                    cidades: []
                }
            }
        },
        watch: {
            'filters.cd_estado': function(val) {
                this.listaCidades(val)
                this.emitFilters()
            },
            'filters.cd_cidade': function() {
                this.emitFilters()
            }
        },
        methods: {
            listaCidades(val) {
                this.options.cidades = [{id: 0, nm_cidade: 'Todas'},..._.filter(JSON.parse(localStorage.getItem("usuario")).opcoes_cidade,["cd_estado", val ?? 9])];
                this.filters.cd_cidade = this.filters.cd_estado == 9 ? 1735 : this.options.cidades[0].id;
            },
            mudaEspecialidades(dado) {
                this.filters.cd_especialidades_list = dado
                this.emitFilters()
            },  
            emitFilters() {
                const list = _.map(this.filtros, (v,k) => k)
                let fil = this.filters

                switch (false) {
                    case _.includes(list, 'agenda'):
                        fil = _.omit(fil, 'ie_sem_agenda')
                        break;
                    case _.includes(list, 'localidade'):
                        fil = _.omit(fil, 'cd_estado')
                        fil = _.omit(fil, 'cd_cidade')
                        break
                    case _.includes(list, 'especialistas'):
                        fil = _.omit(fil, 'nm_especialista')
                        break;
                    case _.includes(list, 'estabelecimentos'):
                        fil = _.omit(fil, 'ds_busca')
                        break;
                }
                this.$emit("filters", fil)
            }
        }
    }
</script>