<template lang="pug">
.main-wrapper.lista
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Locais Atendimento</b>

    CorpoClinicoDialog(v-if='dialogCClinico' :visible.sync:dialogCClinico='dialogCClinico' @close='dialogCClinico = false' :estabId="estabId" :estabName="estabName")
    EstabelecimentosDialog(v-if='dialogEstab' :visible.sync:dialogEstab='dialogEstab' @close='dialogEstab = false' :refConselho='refConselho' :especialista='especialista')

    TabView(@tab-change='onTabChange')
        TabPanel(v-for="tab in tabList" :header='tab' :key="tab")

        EstabelecimentoTabPanel(v-if='tabs == "Estabelecimentos"' @cc-dialog="ccDialog")
        EspecialistaTabPanel(v-if='tabs == "Especialistas"' @estab-dialog='estabDialog')
</template>

<style lang="scss">
.lista {
    @media all and (min-width: 641px) {
        .dataview {
            display: none;
        }
        .p-dialog{
            width: 75% !important;
        }
    }
    @media all and (max-width: 640px) {
        .p-dialog{
            width: 85% !important;
        }
        .datatable {
            display: none;
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
    }
    .p-tabview-panels {
            padding: 0 !important;
            border: none !important;
    }
    .p-tabview {
        padding-bottom: 0;
        @media all and (max-width: 705px) {
            margin-top: auto !important;
        }
    }
    .whatsapp-link {
        margin-left: 8px;
        background-color: #0da64d;
        color: #fff;
        font-weight: 700;
        border-radius: 50%;
        padding: 4px;
        display: inline-block;
        vertical-align: middle;
        width: 28px;
        height: 28px;
        line-height: 24px;
        text-align: center;
        transition: 0.25s;
        &:hover {
            background-color: #0a7d3a;
        }
    }
    .p-button-copy, .p-button-warning {
        margin: .5rem;
        border: 0px solid darkgray;
        background-color: lightgray;
        color: darkslategray;
        &:hover{
            // color: darken($color, $amount: 0) !important;
            filter: brightness(.8) !important;
        }
    }
    .avatar-wrapper {
        overflow: hidden;
        font-size: 0;
        text-align: center;
        img {
            max-height: 8rem;
            max-width: -webkit-fill-available;
        }
    }
    .p-tooltip {
        max-width: none;
    }
    tr td, th {
        padding: 1% !important;
        word-wrap: break-word;
    }
    .p-button-copy, .p-button-warning {
        margin: 5% !important;
    }
    .p-multiselect-filter-container {
        width: 90% !important;
        border-right: 1px solid lightgray;
    }
    .p-dialog-content {
        min-height:45vh;
        overflow: auto
    }
}
</style>
    
<script>
import Tooltip from 'primevue/tooltip'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import CorpoClinicoDialog from '@/components/LocaisAtendimento/CorpoClinicoDialog'
import EstabelecimentosDialog from '@/components/LocaisAtendimento/EstabelecimentosDialog'
import EstabelecimentoTabPanel from '@/components/LocaisAtendimento/EstabelecimentoTabPanel'
import EspecialistaTabPanel from '@/components/LocaisAtendimento/EspecialistaTabPanel'

export default {
    components: { TabView, TabPanel, CorpoClinicoDialog, EstabelecimentosDialog, EstabelecimentoTabPanel, EspecialistaTabPanel},
    directives: { tooltip: Tooltip },
    watch: {
        '$route.name': function(){
           this.$router.go(0) 
        }
    },
    data () {
        return {
            estabId: 0,
            estabName: '',
            refConselho: '',
            especialista: '',
            dialogCClinico: false,
            dialogEstab: false,
            medEmpresa: false,
            tabList: ['Estabelecimentos', 'Especialistas'],
            tabs: 'Estabelecimentos'
        }
    },
    methods: {
        onTabChange () {
            this.tabs = this.tabList[this.tabList.indexOf(this.tabs)+1] ?? this.tabList[0]
        },
        ccDialog (val) {
            this.dialogCClinico = true
            this.estabId = val.estabId
            this.estabName = val.estabName
        },
        estabDialog (val) {
            this.dialogEstab = true
            this.refConselho = val.refConselho
            this.especialista = val.especialista
        }
    }
}
</script> 