<template lang="pug">
div
    label.form-label Especialidades:
    ProgressBar(v-if='waitingEspecialidades' mode="indeterminate" style='margin: 15px 0;')
    .p-inputgroup(v-else)
        MultiSelect.multi(
            v-model="cd_especialidades_list"
            :options='especialidades'
            placeholder="Selecione uma ou mais..."
            optionLabel='nm_especialidade'
            optionValue='id'
            :scrollHeight='height'
            filter
        )
        Button.p-button-danger(v-if='cd_especialidades_list.length > 0' icon='jam jam-rubber' @click='limparSelecao()')
</template>

<style lang="scss">
.p-inputgroup {
    .multi {
        min-width: 85% !important;
        .p-multiselect-header .p-checkbox.p-component {
            display: none !important;
        }
        .p-multiselect-panel {
            width: inherit;
            li {
                white-space: pre-wrap;
            }
        }
    }
    .p-button-danger {
        min-width: 10% !important
    }
    .p-multiselect-filter-container{
        width: 95% !important;
    }
    .p-multiselect-filter p-component p-inputtext {
        border-right: 1px solid !important;
    }
}
</style>

<script>
import Button from "primevue/button";
import MultiSelect from "primevue/multiselect";
import ProgressBar from "primevue/progressbar";
import Especialidade from "@/middleware/controllers/Especialidade";

export default {
    created () {
        this.getEspecialidades() 
        console.log(this.preSelected)
        if(this.preSelected)
            this.cd_especialidades_list = this.preSelected;
    },

    components: { Button, MultiSelect, ProgressBar },
    emits: ['lista-especialidades'],
    props: ['height', 'preSelected'],
    data () {
        return {
            cd_especialidades_list: [],
            waitingEspecialidades: false,
            especialidades: [],
            hideOptions: false,
        }
    },
    watch: {
        cd_especialidades_list: 'emitEspecialidadeList',
        "preSelected": function(value){  
            this.cd_especialidades_list = value 
        }
    },
    methods: {
        emitEspecialidadeList(list) {
            this.$emit('lista-especialidades', list)
        },
        limparSelecao() {
            this.cd_especialidades_list = []
        },
        async getEspecialidades () {
            this.waitingEspecialidades = true
            Especialidade.getAll().then(res=>{
                this.especialidades = res
                this.waitingEspecialidades = false
            }).catch(rej=>{
                this.$toast.error('Erro ao buscar Especialidades')
                console.log('Erro ao buscar Especialidades: ', rej)
            })
        }
    }
}
</script>
