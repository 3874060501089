<template lang="pug">
span
    Filtros(:filtros='filtersOptions' @filters='mudaFiltros')
    ProgressBar(v-if='waiting' mode="indeterminate")
    p.ta-center.text-secondary(v-else-if='list.length == 0') Nenhum registro encontrado.
    div(:style="{'display':waiting ? 'none' : 'block'}" v-else)
        DataView.dataview.my-2(:value="list" layout="grid" :display='waiting')
            template(#grid="{data}")
                .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !data.ie_status }")
                    Panel.panel-list.ta-center(:header="data.nm_especialista" style='position: relative')
                        .ta-left
                            p 
                                b Conselho: 
                                span {{ data.nm_conselho }} {{ data.nr_conselho }} {{ data.ds_uf_conselho }}
                            span
                                .panel-p-span.left
                                    b Especialidades: 
                                    span(v-for='esp in data.especialidades') {{ esp.nm_especialidade }} <br/>
                                .panel-p-span.right
                                    Button.p-button-warning(v-tooltip.top='"Locais de Atendimento"' icon='jam jam-building' @click="estabDialog([data.nm_conselho, data.nr_conselho, data.ds_uf_conselho], data.nm_especialista)")
                            p
                                b Contatos:
                                span(v-if='data.nr_whatsapp_cliente')
                                    br
                                    span {{ data.nr_whatsapp_cliente_f }}
                                    a.whatsapp-link(:href="`https://api.whatsapp.com/send?phone=${ data.nr_telefone_cliente_ws }`" target='_blank')
                                        i(class='jam jam-whatsapp')
                                span(v-if='data.nr_telefone_f')
                                    br
                                    span {{ data.nr_telefone_f }}
                                span(v-if='data.nr_whatsapp')
                                    br
                                    span {{ data.nr_whatsapp_f }}
                                    a.whatsapp-link(:href="`https://api.whatsapp.com/send?phone=${ data.nr_telefone_ws }`" target='_blank')
                                        i(class='jam jam-whatsapp')

        Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

        Panel.datatable(header='Lista de Especialistas')
            Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
            DataTable(:value="list" @sort="onSort($event)" :sortField="order.field" :sortOrder="order.sortOrder" responsiveLayout='scroll')
                Column(field='aq_foto' bodyStyle='text-align: left;' header='Foto' headerStyle='width: 10%')
                    template(#body='{data}') 
                        .avatar-wrapper
                            img(:src='data.aq_foto' :alt="data.nm_especialista")
                Column(field='nm_especialista' bodyStyle='text-align: left;' header='Especialista' headerStyle='width: 30%' sortable)
                    template(#body='{data}')
                        span {{ data.nm_especialista }} - {{ data.nm_conselho }} {{ data.nr_conselho }} {{ data.ds_uf_conselho }}
                Column(field='especialidades' bodyStyle='text-align: left;' headerStyle='width: 14%')
                    template(#header)
                        span Especialidades Ativas 
                        Button.p-button-rounded.p-button-secondary.p-button-raised.p-button-outlined(v-if='filters.ie_sem_agenda!="false"' icon='jam jam-circle' v-text="'?'" style='border="1px solid darkgray;"' v-tooltip.top='"Especialidades listadas podem indicar que existe mais de um cadastro de especialista ativo no mesmo estabelecimento."')
                    template(#body='{data}')
                        span(v-for='especialidade in data.especialidades') {{ especialidade.nm_especialidade }} <br/>
                Column(field='ds_email' bodyStyle='text-align: left;' header='Contato' headerStyle='width: 25%')
                Column(field='nr_telefone' bodyStyle='text-align: left;' header='Contato' headerStyle='width: 11%')
                    template(#body='{data}')
                        span {{ data.nr_telefone_f }}
                Column(bodyStyle='text-align: center;' header='Ações' headerStyle='width: 10%')
                    template(#body='{data}')
                        Button.p-button-warning(v-tooltip.top='"Locais de Atendimento"' icon='jam jam-building' @click="estabDialog([data.nm_conselho, data.nr_conselho, data.ds_uf_conselho], data.nm_especialista)")
            Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")  
</template>
    
<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from "primevue/button";
    import InputText from 'primevue/inputtext'
    import moment from 'moment'
    import { Estabelecimento } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    import Tooltip from 'primevue/tooltip'
    import { fixTelefoneFormat } from './../../utils'
    import MultiSelectEspecialidades from '@/components/CustomComponents/MultiSelectEspecialidades'
    import Filtros from './Filtros'
    import * as _ from 'lodash'

    export default {
        components: { ProgressBar, DataView, Panel, Paginator, DataTable,
                Column, Button, InputText, MultiSelectEspecialidades, Filtros},
        directives: { tooltip: Tooltip },
        emits: ['estab-dialog'],
        computed: {
            show: {
                get() { return this.visible; },
                set(val) { if (!val) this.$emit('close'); }
            }
        },
        data () {
            return {
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                filters: {},
                filtersOptions: {
                    especialistas: true,
                    especialidades: true,
                    localidade: true,
                    agenda: true
                },
                paginator: {
                    page: this.$route.query.pg || 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                order: {
                    field: 'nm_fantasia',
                    sortOrder: 1
                },
                options: {
                    especialidades: []
                },
                getListDebounce: _.debounce(function (params, getFunction) {
                    getFunction(params)
                }, 500, { leading: true }),
            }
        },
        methods: {
            getList (params) {
                this.waiting = true
                if(this.$route.name == "locais_med_empresa") params.ie_atende_medclub_empresa = true;
                return Estabelecimento.corpoClinico(params).then(response => {
                    if (response?.status == 200) {
                        this.paginator.count = response.data.count
                        response.data.results.forEach(especialista => {
                            if (especialista.nr_telefone) especialista.nr_telefone_f = fixTelefoneFormat(especialista.nr_telefone)
                        })
                        this.list = response.data.results
                    }else{
                        this.list = []
                    }
                    this.waiting = false
                    return true
                })
            },
            checkEmptyField (field) {
                if (! this.filters[field].length) this.applyFilters()
            },
            applyFilters (page) {
                this.paginator.page = page || 1
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }

                params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`

                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => {
                if (key == 'nr_name') params[key] = this.filters[key].replace(/(\.|-|_)/g, '')
                else if (key.substr(0, 3) == 'dt_') {
                    if (moment(this.filters[key], 'DD/MM/YYYY').isValid())
                        params[key] = moment(this.filters[key], 'DD/MM/YYYY').format('YYYY-MM-DD')
                    } else if (key == 'ref_conselho') params[key] = this.filters[key].join('')
                    else if (this.filters[key]) params[key] = this.filters[key]
                })
                // Fake loading quando os filtros forem os mesmos
                if (_.isEqual(this.params, params)) {
                    if (!this.waiting) {
                        this.waiting = true
                        setTimeout(() => this.waiting = false, 200)
                    }
                } else {
                    this.params = Object.assign({}, params)
                    this.waiting = true
                    this.getListDebounce(params, this.getList)
                }
            },
            onPage (ev) {
                this.applyFilters(ev.page + 1)
            },
            onSort(ev) {
                this.order.field = ev.sortField
                this.order.sortOrder = ev.sortOrder
                this.applyFilters()
            },
            estabDialog (conselho, especialista) {
                this.$emit('estab-dialog',  {refConselho: conselho.join(','), especialista})
            },
            mudaFiltros(fil) {
                this.filters = fil
                this.applyFilters()
            }
        }
    }
</script>
    
<style lang="scss">
    .panel-p-span {
        &.left {
            width: 80%;
            float:left;
        }
        &.right {
            width: 20%;
            float:right;
        }
    }
</style>