<template lang="pug">
Dialog.cc-dialog(:visible.sync='show' :modal='true')
    template(#header) Corpo Clínico de <u> {{ header }} </u>
    Filtros(:filtros='filtersOptions' :multiHeight='"6.5rem"' @filters='mudaFiltros')
    div.div-spinner(v-if='this.waiting')
        ProgressBar(mode="indeterminate")
    span(v-else)
        DataTable(:value="list" @sort="onSort($event)" removableSort :sortField="order.field" :sortOrder="order.sortOrder")
            template(#empty)
                p.ta-center.text-secondary Nenhum registro encontrado.
            Column(headerStyle='width: 60%;' header='Especialista' :sortable="list.length > 0" field='nm_especialista')
                template(#body='{data}')
                    span {{ data.nm_especialista }} - {{ data.nm_conselho }} {{ data.nr_conselho }} {{ data.ds_uf_conselho }}
            Column(headerStyle='width: 40%;' bodyStyle='vertical-align: middle;' header='Especialidades Ativas' field='especialidades')
                template(#body='{data}')
                    span(v-for='especialidade in data.especialidades') {{ especialidade.nm_especialidade }} <br/>
        Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Panel from 'primevue/panel'
import InputText from 'primevue/inputtext'
import Paginator from 'primevue/paginator'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import MultiSelect from "primevue/multiselect";
import ProgressBar from 'primevue/progressbar'
import { Estabelecimento } from './../../middleware'
import * as _ from 'lodash'
import Filtros from './Filtros';

export default {
    mounted() { this.headerCreate() },
    components: { Button, Dialog, Panel, InputText, Paginator, ProgressBar, DataTable, Column, MultiSelect, Filtros },
    props: ['estabId', 'estabName','visible'],
    computed: {
        show: {
            get() { return this.visible; },
            set(val) { if (!val) this.$emit('close'); }
        }
    },
    data () {
        return {
            list: [],
            waiting: false,
            waitEspecs: false,
            windowInnerWidth: window.innerWidth,
            header: '',
            filters: {
                cd_estabelecimento: this.estabId,
            },
            filtersOptions: {
                especialistas:true,
                especialidades: true,
                agenda: true
            },
            paginator: {
                page: this.$route.query.pg || 1,
                per_page: 5,
                count: 0
            },
            order: {
                field: 'nm_especialista',
                sortOrder: 1
            },
            getListDebounce: _.debounce(function (params, getFunction) {
                getFunction(params)
            }, 500, { leading: true }),
        }
    }, 
    methods: {
        onPage (ev) {
            this.applyFilters(ev.page + 1)
        },
        onSort(ev) {
            this.order.field = ev.sortField
            this.order.sortOrder = ev.sortOrder
            this.applyFilters()
        },
        corpoClinico (params) {
            this.waiting = true 
            if(this.$route.name == "locais_med_empresa") params.ie_atende_medclub_empresa = true;
            Estabelecimento.corpoClinico(params).then(res => {
                this.paginator.count = res.data.count
                _.transform(res.data.results, (r,v)=>{
                    v['especialidades'] = _.uniqBy(v['especialidades'], 'id')
                }, [])
                this.list = res.data.results;
                this.waiting = false
            }).catch(rej => console.log("erro: ", rej))
        },
        applyFilters (page) {
            this.paginator.page = page || 1

            let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page}
            params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`
            if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
            Object.keys(this.filters).forEach((key) => {
                if(this.filters[key])
                    params[key] = this.filters[key]
            })
            // Fake loading quando os filtros forem os mesmos
            if (_.isEqual(this.params, params)) {
                if (!this.waiting) {
                    this.waiting = true
					setTimeout(() => this.waiting = false, 200)
				}
			} else {
                this.params = Object.assign({}, params)
				this.waiting = true
				this.getListDebounce(params, this.corpoClinico)
			}
        },
        headerCreate(){
            this.header = this.estabName.toUpperCase()
        },
        mudaFiltros(fil) {
            this.filters = {...this.filters, ...fil}
            this.applyFilters()
        }
    }
}
</script>

<style lang="scss">
    .text-secondary {
        margin: 20px 0;
    }
</style>