<template lang="pug">
Dialog.cc-dialog(
:visible.sync='show' :modal='true')
    template(#header) Locais de Atendimento de <u> {{ header }} </u>
    Filtros(:filtros='filtersOptions' :multiHeight='"6.5rem"' @filters="mudaFiltros")
    div.div-spinner(v-if='waiting')
        ProgressBar(mode="indeterminate")
    DataTable(v-else :value="list" @sort="onSort($event)" :sortField="order.field" :sortOrder="order.sortOrder" :filters.sync='filters')
        template(#empty)
            p.ta-center.text-secondary Nenhum registro encontrado.
        template
            Column(header='Estabelecimento' headerStyle='width: 48%')
                template(#body='{data}')
                    span(v-tooltip.top='data.nm_razao_social') {{ data.nm_fantasia }}
            Column(header='Especialidades Ativas' bodyStyle='vertical-align: left;' field='especialidades' headerStyle='width: 37%')
                template(#body='{data}')
                    span(v-for='esp in data.especialidades') {{ esp.nm_especialidade }} <br>
            Column(header='Ações' bodyStyle='text-align: center; padding:0' headerStyle='width: 15%')
                template(#body='{data}')
                    Button.p-button-copy(icon='jam jam-files' @click="copy(data)" v-tooltip.top='"Copiar Endereço e Contato"')
    Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")  
</template>
    
<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Panel from 'primevue/panel'
import InputText from 'primevue/inputtext'
import Paginator from 'primevue/paginator'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import SelectButton from 'primevue/selectbutton'
import MultiSelect from "primevue/multiselect";
import ProgressBar from 'primevue/progressbar'
import Filtros from './Filtros.vue';
import { listaEnderecoEstabelecimento, copyToClipboard } from '@/utils'
import Tooltip from 'primevue/tooltip'
import { Estabelecimento } from './../../middleware'
import * as _ from 'lodash'

export default {
    mounted() { this.headerCreate() },
    components: { Button, Dialog, Panel, InputText, Paginator, ProgressBar, DataTable, Column, MultiSelect, SelectButton, Filtros },
    directives: { tooltip: Tooltip },
    props: ['refConselho', 'especialista', 'visible'],
    computed: {
        show: {
            get() { return this.visible; },
            set(val) { if (!val) this.$emit('close'); }
        }
    },
    data () {
        return {
            list: [],
            waiting: false,
            windowInnerWidth: window.innerWidth,
            header: '',
            filters: {
                ref_conselho: this.refConselho
            },
            filtersOptions: {
                estabelecimentos: true,
                especialidades: true,
                agenda: true,
            },
            paginator: {
                page: this.$route.query.pg || 1,
                per_page: 5,
                count: 0
            },
            order: {
                field: 'nm_fantasia',
                sortOrder: 1
            },
            options: {
                especialidades: [],
                agenda: [
                    {label: 'Com', value: 'false', icon: 'jam jam-calendar-alt'}, 
                    {label: 'Sem', value: 'true', icon: 'jam jam-calendar'},
                    {label: 'Com e Sem', value: null, icon: 'jam jam-calendar-alt-f'}
                ]
            },
            getListDebounce: _.debounce(function (params, getFunction) {
                getFunction(params)
            }, 500, { leading: true }),
        }
    },
    methods: {
        onPage (ev) {
            this.applyFilters(ev.page + 1)
        },
        onSort(ev) {
            this.order.field = ev.sortField
            this.order.sortOrder = ev.sortOrder
            this.applyFilters()
        },
        estabs (params) {
            this.waiting = true
            if(this.$route.name == "locais_med_empresa") params.ie_atende_medclub_empresa = true;
            Estabelecimento.byEspecialidades(params).then(res => {
                this.paginator.count = res.data.count
                this.list = res.data.results;
                this.waiting = false
            }).catch(rej => console.error("Erro na busca por estabelecimentos: ", rej))
        },
        applyFilters (page) {
            this.paginator.page = page || 1

            let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page}
            params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`
            if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
            Object.keys(this.filters).forEach((key) => {
                if(this.filters[key])
                    params[key] = this.filters[key]
            })
            // Fake loading quando os filtros forem os mesmos
            if (_.isEqual(this.params, params)) {
                if (!this.waiting) {
                    this.waiting = true
                    setTimeout(() => this.waiting = false, 200)
                }
            } else {
                this.params = Object.assign({}, params)
                this.waiting = true
                this.getListDebounce(params,  this.estabs)
            }
        },
        headerCreate(){
            this.header = this.especialista.toUpperCase()
        },
        copy(propsData){
            copyToClipboard(listaEnderecoEstabelecimento(propsData, true))
            this.$toast.success('Endereço e Contato copiados!')
        },
        mudaFiltros(fil) {
            this.filters = {...this.filters,...fil}
            this.applyFilters()
        }
    }
}
</script>

<style lang="scss">
    .div-spinner {
        text-align: center
    }
    .text-secondary {
        margin: 20px 0;
    }
    .selectButton {
        i {
            font-size: x-large;
        }
    }
</style>